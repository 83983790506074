import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-sec-cookies',
  templateUrl: './user-sec-cookies.component.html',
  styleUrls: ['./user-sec-cookies.component.css']
})
export class UserSecCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
