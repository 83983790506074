import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kontak',
  templateUrl: './kontak.component.html',
  styleUrls: ['./kontak.component.css']
})
export class KontakComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
