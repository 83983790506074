import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-nav',
  templateUrl: './bot-nav.component.html',
  styleUrls: ['./bot-nav.component.css']
})
export class BotNavComponent implements OnInit {
  mado(){

    console.log("Imad");
  }

  constructor() { }

  ngOnInit() {
     
    
  
  

  }
 
}
