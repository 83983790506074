import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { TopNavComponent } from './Header/top-nav/top-nav.component';
import { BotNavComponent } from './Header/bot-nav/bot-nav.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContainerComponent } from './pages/container/container.component';
import { SliderComponent } from './pages/container/slider/slider.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PagesComponent } from './pages/pages.component';
import { KontakComponent } from './pages/kontak/kontak.component';
import { TjansterComponent } from './pages/tjanster/tjanster.component';
import { OmComponent } from './pages/om/om.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { EkonomiComponent } from './pages/ekonomi/ekonomi.component';
import { LoginComponent } from './pages/login/login.component';
import { SignuppComponent } from './pages/signupp/signupp.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { UserSecCookiesComponent } from './pages/user-sec-cookies/user-sec-cookies.component';
import { ChartComponent } from './pages/ekonomi/chart/chart.component';
import { FacebookModule } from 'ngx-facebook';
import { TjanstssidanComponent } from './pages/tjanstssidan/tjanstssidan.component';
import { AktierComponent } from './pages/aktier/aktier.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TopNavComponent,
    BotNavComponent,
    FooterComponent,
    ContainerComponent,
    SliderComponent,
    PagesComponent,
    KontakComponent,
    TjansterComponent,
    OmComponent,
    PartnerComponent,
    MaintenanceComponent,
    Error404Component,
    EkonomiComponent,
    LoginComponent,
    SignuppComponent,
    ProfileComponent,
    TermsConditionsComponent,
    UserSecCookiesComponent,
    ChartComponent,
    TjanstssidanComponent,
    AktierComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    FacebookModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
