import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-om',
  templateUrl: './om.component.html',
  styleUrls: ['./om.component.css']
})
export class OmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
