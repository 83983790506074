import { AktierComponent } from './pages/aktier/aktier.component';
import { TjanstssidanComponent } from './pages/tjanstssidan/tjanstssidan.component';

import { UserSecCookiesComponent } from './pages/user-sec-cookies/user-sec-cookies.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { SignuppComponent } from './pages/signupp/signupp.component';
import { EkonomiComponent } from './pages/ekonomi/ekonomi.component';
import { Error404Component } from './pages/error404/error404.component';
import { OmComponent } from './pages/om/om.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { TjansterComponent } from './pages/tjanster/tjanster.component';
import { KontakComponent } from './pages/kontak/kontak.component';
import { ContainerComponent } from './pages/container/container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path:'kontakt',component: KontakComponent },
  { path:'tjanster',component: TjansterComponent },
  { path:'omoss',component: OmComponent },
  { path:'partner',component: PartnerComponent },
  { path:'',component: ContainerComponent },
  { path:'SverigesEkonomi',component: EkonomiComponent},
  { path:'registrering',component: SignuppComponent},
  { path:'login',component: LoginComponent},
  { path:'profil',component: ProfileComponent},
  { path:'infoochvillkor',component: TermsConditionsComponent},
  { path:'anvandarvillkor',component: UserSecCookiesComponent},
  { path:'tjanst',component: TjanstssidanComponent},
  { path:'Aktier',component: AktierComponent},
  { path:'**',component: Error404Component }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
